import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";
import { useTranslation } from "react-i18next";

// import JwtTimer from "components/JwtTimer/JwtTimer";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

import "./TopNav.scss";

const TopNav = () => {
  const { logout } = useAuth();
  const { logout2 } = useUser();
  const { userData } = useUser();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { t /*, i18n*/ } = useTranslation();

  const handleLogout = () => {
    logout();
    logout2();
  };

  return (
    <div className="topnav flex items-center justify-end">
      <div>
        <span className="app-title font-base text-black">edups</span>{" "}
        <span className="app-subtitle font-xs text-black">
          | Educational prescribing system
        </span>
      </div>
      <div className="ml-auto mr-auto">
        {/*
        <p className="center font-xs">
          {`${userData.user_role} session`}
          <span className="ml-1">
            <JwtTimer label={t("Remaining session time:")} />
          </span>
        </p>
      */}
      </div>
      <p className="font-xs d-inline-block">
        {t("Hello")}, {`${userData.first_name} ${userData.last_name}!`}
      </p>
      <Link to="/profile" className="profile-link" title={t("Edit profile")}>
        <i className="ri-account-circle-fill"></i>
      </Link>
      <Link className="font-xs" onClick={() => setShowLogoutModal(true)}>
        {t("Log out")}
      </Link>
      {showLogoutModal && (
        <ConfirmationModal
          title="Log out?"
          message={t("Are you sure you want to log out?")}
          yesMessage={t("Log out")}
          className="w-auto"
          onClose={() => setShowLogoutModal(false)}
          onSubmit={handleLogout}
        />
      )}
    </div>
  );
};

export default TopNav;
