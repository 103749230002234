import { Listbox } from "@headlessui/react";

import "./Select.scss";

const Select = ({
  id,
  className = "",
  label,
  options,
  value,
  allowNone = true,
  noneLabel = "None",
  onChange,
  onBlur = null,
  onFocus = null,
  readOnly,
  required = false,
  ...rest
}) => {
  className += ` select-value__${String(value?.value)
    .toLowerCase()
    .replace(" ", "-")}`;
  return (
    <div
      id={id}
      className={`select-container ${className}`}
      readOnly={readOnly}
    >
      <label htmlFor={id} className={value && "filled"}>
        {label}
        {required ? " *" : ""}
      </label>
      <Listbox
        defaultValue={value}
        onChange={onChange}
        /**
         * since Listbix is a Fragment, as="div" is needed for the implementation of onBlur
         * onBlur fires twice, once for the button and once for the list
         * the list is what is needed
         */
        as="div"
        onBlur={(e) => {
          if (e.target.tagName.toLowerCase() === "ul" && onBlur) onBlur(e);
        }}
        onFocus={(e) => {
          if (onFocus) onFocus();
        }}
      >
        <Listbox.Button className="btn-select flex">
          <div>{value?.label}</div>
          <div className="ml-auto">
            <i className="ri-arrow-down-s-line"></i>
          </div>
        </Listbox.Button>
        <Listbox.Options>
          {allowNone && (
            <Listbox.Option key={-1} value={""} className="flex">
              <span className="mr-auto">
                <i>{noneLabel}</i>
              </span>
              {(value === "" || value === null) && (
                <i className="ri-check-line"></i>
              )}
            </Listbox.Option>
          )}
          {options.map((option, idx) => (
            <Listbox.Option key={idx} value={option} className="flex">
              <span className="mr-auto">{option.label}</span>
              {value?.value === option.value && (
                <i className="ri-check-line"></i>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default Select;
